/* TWEET LIST */
#tweet-list {
    padding: 16px 0px;
}

#tweet-list a {
    color: black;
    text-decoration: none;
}

.tweet-item {
    padding: 10px 0;
    min-height: 100px;
    display: block;
}

.tweet-item:hover {
    background-color: #eee;
}

.tweet-pic {
    height: 80px;
    float: right;
    right: 0;
}

.strip {
    border-top: 1px solid silver;
    border-top: 1px solid silver;
    width: 100%;
}

.strip img.profile-pic {
    float: left;
    margin-right: 10px;
    margin-top: 5px;
    height: 60px;
}

.light {
    color: grey;
}

.summary {
    margin-left: 60px;
    margin-top: 5px;
}
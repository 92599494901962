/*@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);*/

@tailwind base;
@tailwind components;
@tailwind utilities;

*, ::after, ::before {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 0.8rem;
  color: #fff;
  background-color: #012e40;
  webkit-tap-highlight-color: #222;
  top: 0;
  margin: 0;
    padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rangeSlider {
  position: absolute;
  margin: 0 auto;
}

a {
  color: #c7c7c7;
  /*transition: all 0.35s;*/
}

a {
  color: #fff;
  outline: 0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #ffffffa6;
  outline: 0;
}

a .toggle-link {
  text-decoration: none;
}

a .toggle-link:visited {
  /* color: #0d6efd;*/
}

a .nolink {
  color: black;
  text-decoration: none;
}

a .islink {
  color: #007fff;
  text-decoration: underline;
  cursor: pointer;
}

em {
  font-style: normal;
}

i {
  text-style: none !important;
}

p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  font-family: "Roboto";
  color: #fff;
  line-height: 1.7rem;;
}

h1 {
  color: #fff;
  font-size: 2.0rem;
  font-weight: 900;
}

h2 {
  color: #fff;
  font-weight: 100;
  font-size: 1.5rem;
}

h3 {
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 300;
  color: #fff;
  color: #fff;
  margin-bottom: 0.35em;
}

h5 {
  font-size: 1.2rem;
  color: #fff;
}

h4,
h6 {
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 400;
  color: #ffffff8f;
  font-weight: 900;
}

.active--button {
  background-color: #fff;
  color: #000000;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#article-template h2 {
  margin-top: 2.2rem;
  font-size: 1.7rem;
  line-height: 2.2rem;;
}

#article-template h3 {
  margin-top: 1.9rem;
  font-size: 1.4rem;
}

#article-template ul li {
  margin-top: 0.5rem;
  font-size: 1.1rem;
  color: #fff
}

.custom-mobile-sidebar {
  height: calc(100vh - 64px) !important;
}

.custom-modal .MuiDialog-container .MuiDialog-paper {
  h2 {
    font-size: 1.5rem;
    font-weight: 900;
    color: black;
  }
  h3 {
    font-size: 1.2rem;
    font-weight: 900;
    color: black;
  }
  p {
    font-size: 1.0rem;
    color: black; 
  }
  padding: 20px;
  color: black; /* Ensures text is black */
}

.deck-tooltip {
  pointer-events: none;
  transform: translateX(100px) translateY(-100%);
  transition: transform 0.1s ease-out;
  background-color: red;
  padding-left: 20px;
}

@media screen and (max-width: 767px) {

  .bottom-indicators {
    padding-bottom: 0px;
  }

  .bottom-indicators-safari {
    padding-bottom: -75px; /* 75px*/
  }


}


button[type*="submit"] {
  @apply border-none rounded !mt-[15px] bg-[#3CA6A6] px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-[#35d2d2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
}

button[type*="submit"]:not(:first-child) { @apply ml-2 }
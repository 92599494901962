.rightSidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 170px;
  height: 60%;
  overflow-y: scroll;
  opacity: 0.9;
  z-index: 1;
  margin-top: 70px;
  margin-right: 5px;
}

.mapboxgl-popup-content {
  padding: 0px;
  background-color: black;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: black;
}
body {
  overflow-x: hidden;
}
.carousel {
  /* left: 260px !important; */
  position: static !important;
}
.slick-prev {
  position: absolute !important;
  top: auto !important;
  bottom: 30px !important;
  left: 270px !important;
}
.slick-next {
  position: absolute !important;
  top: auto !important;
  bottom: 30px !important;
}
.storm-carousel{
  width: 100%;
}

.deck-tooltip {
  pointer-events: none; /* Make sure tooltip doesn't block pointer events */
  transform: translateX(10px) translateY(-100%); /* Move right from the pointer and up by its own height */
  transition: transform 0.1s ease-out; /* Optional: smooth transition for the tooltip movement */
  background-color: red;
}

/* Style the red dot */
.red-dot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%; /* Makes it a perfect circle */
}

/* Style the blue dot */
.blue-dot {
  width: 8px;
  height: 8px;
  border: 1px solid rgb(143, 143, 143);
  background-color: rgb(0, 204, 255);
  border-radius: 50%; /* Makes it a perfect circle */
}  


 @media screen and (min-width: 300px) and (max-width: 790px) {
  #deckgl-wrapper {
    width: 120% !important;
  }
   .storm-carousel{
    width: 125% !important;
   }
   .rangeSlider{
     display: none !important;
   }
}

section a {
  text-decoration: underline !important;
}

.outlook-counter {
  margin: 0;
  min-width: 230px !important;
  height: 180px;
  float: left;
  display: inline;
  text-align: center;
  border: 1px solid rgb(0, 0, 0);
  box-shadow: 2px 2px;
  border-radius: 4px;
}

.outlook-counter:hover {
  color: #222020 !important;
  box-shadow: 0px
}

.most-liked-photo {
  height: auto;
}

#most-liked-photos {
  display: flex;
  justify-content: space-between;
}

.header-indicator {
  padding: 15px 5px !important;
  width: 100%;
}

.header-indicator .counter {
  font-size: 1.5em;
  font-weight: 700 !important;
}

.header-indicator .counter-info {
  font-size: 0.9em;
}

.btn-tornado {
  background-color: #ff0000 !important;
}

.btn-other {
  background-color: #76aeba !important;
}

.significant-selection {
  background: #fff;
  text-align: left;
  padding: 10px;
}

.significant-selection h5 {
  margin-bottom: 2px;
}

.significant {
  min-width: 30% !important;
}

.significant-date {
  font-size: 0.9em;
}

a.significant {
  font-size: 14px;
  text-decoration: none !important;
}

.significant > h5 {
  color: #012432;
  font-weight: 900;
  font-size: 1.1em;
}

.day0MaxOutlook h3 {
  color: #000;
  font-size: 1.2em;
  font-weight: 900;
}

.eventdate {
  font-size: 1.3em;
}

.hail_wind {
  font-size: 0.92em;
  margin-top: 12px;
}

.outlook-item {
  word-wrap: break-word; /* Forces the text to wrap within the container */
  white-space: normal; /* Ensures the text will wrap */
}

.affected-states {
  font-size: 0.6em !important;
  font-weight: 400;
  word-spacing: 0.1em;
  display: inline-block !important; /* Makes the span behave like a block element for proper wrapping */
  font-family: Tahoma, sans-serif !important; /* You can choose a narrower font */
}

.event-widgets {
  height: 45vh;
  overflow: auto;
}

.tornado-count {
  font-size: 1.45em;
  margin-bottom: 5px;
  white-space: nowrap;
}

.significant .d-block .small {
  font-size: 0.5em;
  color: red;
}

.significant-image {
  float: left;
  width: 40%;
  padding: 3px 5px;
}

.break-month {
  clear: left;
}

#storm-report-table {
  overflow-x: auto;
}

#storm-report-table .table {
  min-width: 100%;
}

.p-2 {
  padding: 0rem !important;
  margin: 0rem !important;
}

#siginificant-list-wiki {
  background-color: #012432ba;
  overflow-y: scroll;
  height: 400px;
  overflow-y: auto !important;
  /*width: 600px;*/
  border-radius: 0.6em !important;
  /*margin: 20px 0px 20px 0px;*/
  /*padding: 10px 0px 10px 0px;*/
}

#siginificant-list-wiki > ul > li {
  font-size: 1.2em;
  font-weight: 900;
  color: #fff;
  padding: 0.3rem 1rem;
  margin-right: 1rem;
  display: grid;
  border-bottom: 0.5px solid #ffffff54;
}

#filter {
  color: #fff;
  background-color: #012432ba;
  border-radius: 0.6em !important;
  margin: 20px 0px 20px 0px;
  padding: 20px;
}

.filter-right {
  width: 100%;
}

.btn-danger {
  font-size: 1em;
  color: #fff;
  background-color: #3ca6a6;
  border-radius: 0.6em !important;
  border: 0px;
  margin: 0px;
  padding: 3px 6px 3px 6px;
}

.btn-danger hover {
  background-color: #3ca6a68f !important;
}

/* Outlook generics */
/* OUTLOOK GENERICS */
a h6.outlook-counter {
  text-decoration: none !important;
  color: #000;
  padding: 3px 0;
  font-size: 15px;
  font-family: sans-serif;
  cursor: pointer;
  width: 100%;
}

.lvl-bg-und, .lvl-bg-und:hover {
  background-color: #eee;
}

.lvl-bg-nostorms, .lvl-bg-nostorms:hover {
  background-color: #fff;
}

.lvl-bg-TSTM, .lvl-bg-TSTM:hover {
  background-color: #c0e8c0;
}

.lvl-bg-MRGL, .lvl-bg-MRGL:hover {
  background-color: #01c500;
}

.lvl-bg-SLGT, .lvl-bg-SLGT:hover {
  background-color: #f6f600;
}


.lvl-bg-ENH, .lvl-bg-ENH:hover {
  background-color: #e69600;
}

.lvl-bg-MDT, .lvl-bg-MDT:hover {
  background-color: #ff0000;
}

.lvl-bg-HIGH, .lvl-bg-HIGH:hover {
  background-color: #ff00ff;
}

.tor-lvl-bg-0 {
  background-color: #fff;
}

.tor-lvl-bg-2 {
  background-color: #008b00;
}

.tor-lvl-bg-5 {
  background-color: #8b4726;
}

.tor-lvl-bg-10 {
  background-color: #ffc800;
}

.tor-lvl-bg-15 {
  background-color: #ff0000;
}

.tor-lvl-bg-30 {
  background-color: #ff00ff;
}

.tor-lvl-bg-45 {
  background-color: rgb(145, 44, 238);
}

.month-year-header-wrapper {
  margin-bottom: 10px; /* Adjust this margin as needed */
}


@media (max-width: 540px) { /* Adjust the breakpoint as needed */
  .outlook-counter {
      min-width: 100% !important; /* Allow each box to fill the screen on very small devices */
  }
  .hail_wind {
    font-size: 0.8em
  }
}